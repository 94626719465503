import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Settings from "../pages/Settings";
import io from "socket.io-client";
import LocalStorage from "../utils/LocalStorage";
import { ThemeProvider } from "styled-components";
import { createTheme } from "../styles/createTheme";
import { useTheme } from "../hook/theme";

import { createGlobalStyle } from "styled-components";
import { ToastProvider } from "../hook/toast";

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.colors.Thumb};
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: ${({ theme }) => theme.colors.Track};
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  border-radius: 10px;
}
`;

const Rotas = () => {
  const { theme } = useTheme();
  const isLogged = LocalStorage.getValue("token");
  const socket = io("https://api.chat.jit.technology/", {
    auth: {
      token: LocalStorage.getValue("token"),
    },
  });
  return (
    <>
      <ThemeProvider theme={createTheme(theme)}>
        <ToastProvider>
          <GlobalStyle />
          <Routes>
            {isLogged ? (
              <Route path="/">
                <Route path="/" element={<Home socket={socket} />} />
                <Route path="/settings" element={<Settings socket={socket}/>} />
              </Route>
            ) : (
              <Route path="/" element={<Login />} />
            )}
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

export default Rotas;
