import EmojiPicker from "emoji-picker-react";
import { EmojiComponent } from "./styles";
import LocalStorage from "../../../../../../utils/LocalStorage";


const EmojiModal = ({ isOpen, onClose, setNewMessage }) => {
  const isDark = LocalStorage.getTheme() === "Escuro";
  const emojiStyle = LocalStorage.getEmojiStyle();
  if (!isOpen) return null; 
  return (
    <EmojiComponent>
      <EmojiPicker
        emojiStyle={emojiStyle}
        theme= {isDark ? "dark" : "light"}
        autoFocusSearch={true}
        onEmojiClick={(emojiData) =>{
            setNewMessage((prev) => prev + emojiData.emoji);
        }}
      />
    </EmojiComponent>
  );
};

export default EmojiModal;
