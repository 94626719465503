import { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  DialogContainer,
  DialogOverlay,
  Header,
  Title,
  Label,
  Button,
  ButtonCadastro,
  DivButton,
  Form,
  ErrorSpan,
  Checkbox,
  CheckboxContainer,
  ModalImage,
  ModalImageContainer,
} from "./styles";
import * as DialogRadix from "@radix-ui/react-dialog";
import { X } from "phosphor-react";
import Input from "../../../../../../../../shared/components/InputForm/index.jsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import UserService from "../../../../../../../../shared/services/User/UserService.js";
import * as z from "zod";
import { useToast } from "../../../../../../../../hook/toast.jsx";

const Dialog = ({
  open,
  onChangeOpen,
  showIconClose = true,
  update,
  setUpdate,
}) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024;
  const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];

  const userScheme = z.object({
    fullNameContact: z.string().min(1, "Nome é obrigatório"),
    emailUser: z.string().email("Email inválido").min(1, "Email é obrigatório"),
    passwordUser: z.string().min(8, "A senha deve ter no mínimo 8 caracteres"),
    isRootUser: z.boolean(),
    userImage: z
      .any()
      .refine((file) => file instanceof File, {
        message: "O arquivo precisa ser uma imagem",
      })
      .refine((file) => file.size <= MAX_FILE_SIZE, {
        message: "O tamanho da imagem deve ser menor que 5MB",
      })
      .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), {
        message:
          "Formato de imagem inválido. Apenas JPEG, JPG e PNG são aceitos",
      }),
  });

  const { addToast } = useToast();
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    resolver: zodResolver(userScheme),
  });

  useEffect(() => {
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl); 
    } else {
      setPreview(null); 
    }

    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  useEffect(() => {
    if (!open) {
      reset();
      setFile(null); // Resetar a imagem ao fechar o modal
    }
  }, [open, reset]);

  const handleCloseDialog = useCallback(() => {
    onChangeOpen(false);
  }, [onChangeOpen]);

  const onSubmit = async (data) => {
    const response = await UserService.createUser(data);
    if (response.status === 201) {
      addToast({
        type: "success",
        title: "Usuário cadastrado com sucesso",
        description: "O usuário foi cadastrado com sucesso",
      });
      setUpdate(!update);
      onChangeOpen(false);
    } else {
      addToast({
        type: "error",
        title: "Erro ao cadastrar usuário",
        description: "Ocorreu um erro ao cadastrar o usuário",
      });
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  return (
    <DialogRadix.Root open={open} onOpenChange={onChangeOpen}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay asChild>
          <DialogOverlay>
            <DialogRadix.Content asChild>
              <DialogContainer>
                <Container>
                  <Header>
                    <Title>Criar usuário:</Title>
                    <DialogRadix.Close asChild>
                      {showIconClose && (
                        <X
                          onClick={handleCloseDialog}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </DialogRadix.Close>
                  </Header>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Label>Foto de usuário:</Label>
                    <Input
                      type="file"
                      ref={fileInputRef}
                      {...register("userImage")}
                      onChange={(event)=>{
                        const selectedFile = event.target.files[0];
                        if (selectedFile) {
                          setFile(selectedFile);
                        }
                      }}
                    />
                    {preview && (
                      <ModalImageContainer>
                      <ModalImage
                      src={preview}
                      alt="Preview da imagem selecionada"
                    />
                    </ModalImageContainer>
                      )}

                    <Label>Nome:</Label>
                    <Input
                      type="text"
                      placeholder="Nome"
                      {...register("fullNameContact")}
                    />
                    <ErrorSpan
                      $span={errors.fullNameContact?.message ? true : false}
                    >
                      {errors.fullNameContact?.message || "a"}
                    </ErrorSpan>

                    <Label>Email:</Label>
                    <Input
                      type="email"
                      placeholder="Email"
                      {...register("emailUser")}
                    />
                    <ErrorSpan $span={errors.emailUser?.message ? true : false}>
                      {errors.emailUser?.message || "a"}
                    </ErrorSpan>

                    <Label>Senha:</Label>
                    <Input
                      type="password"
                      placeholder="Senha"
                      {...register("passwordUser")}
                    />
                    <ErrorSpan
                      $span={errors.passwordUser?.message ? true : false}
                    >
                      {errors.passwordUser?.message || "a"}
                    </ErrorSpan>

                    <CheckboxContainer>
                      <Label>É Administrador?</Label>
                      <Checkbox type="checkbox" {...register("isRootUser")} />
                    </CheckboxContainer>
                    <ErrorSpan
                      $span={errors.isRootUser?.message ? true : false}
                    >
                      {errors.isRootUser?.message || "a"}
                    </ErrorSpan>

                    <DivButton>
                      <Button onClick={handleCloseDialog}>Cancelar</Button>
                      <ButtonCadastro type="submit">Cadastrar</ButtonCadastro>
                    </DivButton>
                  </Form>
                </Container>
              </DialogContainer>
            </DialogRadix.Content>
          </DialogOverlay>
        </DialogRadix.Overlay>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  );
};

export default Dialog;
