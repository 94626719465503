import ContactApi from "./Api/ContactApi";

class ContactService {
  async getContact() {
    try {
      const response = await ContactApi.getContacts();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  
  async getAllContacts() {
    try {
      const response = await ContactApi.getAllContacts();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getUserContactsByUser(id) {
    try {
      const response = await ContactApi.getUserContactsByUser(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getUserContacts() {
    try {
      const response = await ContactApi.getUserContacts();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async createUserContact(data) {
    try {
      const response = await ContactApi.createUserContact(data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async deleteUserContact(id) {
    try {
      const response = await ContactApi.deleteUserContact(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new ContactService();