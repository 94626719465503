import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  ContainerCollumContent,
  MessagesBox,
  SendMessages,
  AttachFileIconStyled,
  SendStyled,
  NoteStyled,
  NoMessage,
  NoMessageP,
  MessagesContainer,
  MicrophoneStyled,
  AudioPlayerStyled,
  SendButtonStyled,
  CancelButtonStyled,
  AudioMonitor,
  EmojiStyled,
} from "./styles";
import * as Popover from "@radix-ui/react-popover";

import ContactSuperior from "./components/ContactSuperior";
import Message from "./components/Message";
import Modal from "./components/Modal";
import { IconButton } from "@mui/material";
import Input from "../../../../shared/components/Input";
import { getFileType } from "../../../../utils/format";
import { jwtDecode } from "jwt-decode";
import { formatTime } from "../../../../utils/format";
import EmojiModal from "./components/Emoji";

const Chat = ({
  socket,
  selectedContact,
  lateralContacts,
  setLateralContacts,
  currentMessages,
  messagesEndRef,
  navigation,
  setNavigation,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [isNote, setIsNote] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [senderUserID, setSenderUserID] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingInterval = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const manipulateMessages = useCallback((messages) => {
    if (messages.length === 0) return [];

    let currentDay = null;
    const messagesWithFlags = [];

    messages.forEach((message) => {
      const date = new Date(message.sendDateMessage);
      const day = date.getDate();

      if (day !== currentDay) {
        currentDay = day;
        messagesWithFlags.push({
          flag: `Mensagens do dia ${date.toLocaleDateString()}`,
          isFlag: true,
          date,
        });
      }

      messagesWithFlags.push(message);
    });
    return messagesWithFlags;
  }, []);

  useEffect(() => {
    if (audioBlob) {
      const audio = new File([audioBlob], "audioMessage.webm", {
        type: "audio/webm",
      });
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Audio = reader.result;
        setFile(base64Audio);
        setTitle("Mensagem de Áudio");
      };

      reader.readAsDataURL(audio);
    }
  }, [audioBlob]);

  useEffect(() => {
    if (file && title === "Mensagem de Áudio") {
      handleSendMessage();
    }
  }, [file]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setSenderUserID(() => decoded.id);
    }
  }, []);

  const startRecording = () => {
    setFile(null);
    setTitle("");
    setRecordingTime(0);
    try {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(mediaRecorder);
        mediaRecorder.start();
        setIsRecording(true);

        recordingInterval.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);

        mediaRecorder.ondataavailable = (e) => {
          setAudioBlob(e.data);
        };
      });
    } catch (err) {
      console.log("Error recording audio", err);
    }
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setIsRecording(false);
    clearInterval(recordingInterval.current);
  };

  const handleSendMessage = () => {
    if ((newMessage.trim() || file) && selectedContact) {
      const data = {
        typeMessage: file ? getFileType(file) : "chat",
        toContactMessage: selectedContact,
        contentMessage: file ? title : newMessage,
        isNoteMessage: isNote,
        idReplyMessage: null,
        fileContentMessage: file,
        senderUserId: senderUserID,
      };
      socket.emit("sendMessage", data, (err) => {
        if (!err) {
          const lastMessageText = file ? title : newMessage;
          setLateralContacts((prev) =>
            prev.map((contact) => {
              if (contact.id === selectedContact) {
                return {
                  ...contact,
                  lastMessage: lastMessageText,
                  newMessages: true,
                };
              }
              return contact;
            })
          );
        }
      });
      setNewMessage("");
      setIsNote(false);
      setFile(null);
      setTitle("");
    }
    onCloseModal();
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();

    const fileDropped = e.dataTransfer.files[0];
    if (fileDropped) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(fileDropped);
    }
  };

  const handleFileSelect = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const onEmojiModalClose = () => {
    setIsEmojiOpen(false);
  };

  return (
    <ContainerCollumContent $navigation={navigation}>
      {selectedContact && (
        <ContactSuperior
          setNavigation={setNavigation}
          socket={socket}
          dataContact={
            lateralContacts.find((contact) => contact.id === selectedContact) ||
            {}
          }
        />
      )}
      {selectedContact && (
        <MessagesBox>
          {currentMessages.length >= 1 ? (
            <MessagesContainer>
              {manipulateMessages(currentMessages).map((message, index) => (
                <Message
                  key={index}
                  message={message}
                  socket={socket}
                  dataContact={
                    lateralContacts.find(
                      (contact) => contact.id === selectedContact
                    ) || {}
                  }
                />
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>
          ) : (
            <NoMessage>
              <NoMessageP></NoMessageP>
            </NoMessage>
          )}
        </MessagesBox>
      )}
      <SendMessages>
        {getFileType(file) === "ptt" || getFileType(file) === "audio" ? (
          <>
            <AudioPlayerStyled controls src={file} />
            <SendButtonStyled onClick={handleSendMessage}>
              Enviar
            </SendButtonStyled>
            <CancelButtonStyled
              onClick={() => {
                setFile(null);
                setTitle("");
              }}
            >
              Cancelar
            </CancelButtonStyled>
          </>
        ) : (
          <>
            <Popover.Root>
              <Popover.Trigger asChild>
                <EmojiStyled
                  onClick={() => {
                    setIsEmojiOpen(true);
                  }}
                />
              </Popover.Trigger>
              <Popover.Content>
                <EmojiModal
                  isOpen={isEmojiOpen}
                  onClose={onEmojiModalClose}
                  setNewMessage={setNewMessage}
                />
              </Popover.Content>
            </Popover.Root>
            <Input
              type="text"
              placeholder="Digite sua mensagem"
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
              }}
              onKeyDown={handleKeyDown}
            />
            <IconButton
              color="default"
              component="label"
              onClick={() => setIsModalOpen(true)}
            >
              <AttachFileIconStyled />
            </IconButton>

            <SendStyled
              onClick={handleSendMessage}
              style={{ cursor: "pointer" }}
            />
            <NoteStyled
              onClick={() => {
                setIsNote(!isNote);
              }}
              style={{ cursor: "pointer" }}
              $isNote={isNote}
            />
            <MicrophoneStyled
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                } else {
                  startRecording();
                }
              }}
              $isRecording={isRecording}
            />
            {isRecording && (
              <AudioMonitor>Gravando: {formatTime(recordingTime)}</AudioMonitor>
            )}
          </>
        )}
      </SendMessages>

      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onDrop={handleFileDrop}
        file={file}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        handleFileSelect={handleFileSelect}
        title={title}
        setTitle={setTitle}
        handleSendMessage={handleSendMessage}
      />
    </ContainerCollumContent>
  );
};

export default Chat;
