import styled from "styled-components";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Send } from "@mui/icons-material";
import { Note, Microphone, Smiley } from "phosphor-react";

export const ContainerCollumContent = styled.div`
  flex: 1;
  width: 30vw;
  height: 95%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  @media (max-width: 800px) {
    display: ${({ $navigation }) =>
      $navigation === "chat" ? "block" : "none"};
  }
`;

export const MessagesBox = styled.div`
  display: flex;
  width: 100%;
  height: 83%;
  max-height: 80vh;
  background-color: ${({ theme }) => theme.colors.BackgroundMessage};
  align-items: center;
  justify-content: flex-end;
  scroll-behavior: smooth;
  flex-direction: column;
  overflow-y: auto;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

export const NoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const NoMessageP = styled.p`
  color: ${({ theme }) => theme.colors.Text};
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: 500;
`;

export const SendMessages = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.Primary};
`;

export const DragBox = styled.div`
  width: 100%;
  height: 200px; /* Altura fixa para acomodar a imagem */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.colors.Primary};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  margin-bottom: 1rem;
  position: relative;
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.Outline};
`;

export const DragDropArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${({ theme }) => theme.colors.Outline};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.BackgroundMessageStrong};
  margin-bottom: 1rem;
`;

export const AttachFileIconStyled = styled(AttachFileIcon)`
  color: ${({ theme }) => theme.colors.Text};
`;

export const SendStyled = styled(Send)`
  color: ${({ theme }) => theme.colors.Text};
  z-index: 10;
`;

export const NoteStyled = styled(Note)`
  color: ${({ theme, $isNote }) =>
    $isNote ? theme.colors.Note : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  margin-left: 10px;
  z-index: 10;
`;

export const MicrophoneStyled = styled(Microphone)`
  color: ${({ theme, $isRecording }) =>
    $isRecording ? "red" : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  margin-left: 5px;
  z-index: 10;
`;

export const EmojiStyled = styled(Smiley)`
  color: ${({ theme, $isNote }) =>
    $isNote ? theme.colors.Note : theme.colors.Text};
  width: 40px;
  cursor: pointer;
  margin-left: 10px;
  z-index: 10;
`;

export const AudioMonitor = styled.div`
  color: ${({ theme }) => theme.colors.Text};
`;

export const AudioPlayerStyled = styled.audio`
  width: 100%;
  margin: 10px 0;
  outline: none;
`;

export const SendButtonStyled = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
`;

export const CancelButtonStyled = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: red;
  }

  &:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
`;
