import imageContato from "../assets/images/imageContato";

export const format = async (lateral, getImage) => {
  const array = await Promise.all(
    lateral.map(async (element) => {
      let data = new Date(element.send_date_message);
      const dataAtual = new Date();
      let formattedDate;

      if (dataAtual.getDate() - data.getDate() > 1) {
        formattedDate = `${data.getDate().toString().padStart(2, "0")}/${(
          data.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${data.getFullYear()}`;
      } else {
        const hours = data.getHours().toString().padStart(2, "0");
        const minutes = data.getMinutes().toString().padStart(2, "0");
        formattedDate = `${hours}:${minutes}`;
      }

      const lastMessage = element.image_message
        ? "imagem"
        : element.content_message;

      let trueImage;
      if (
        element.picture_filename_contact === null ||
        element.picture_filetype_contact === null
      ) {
        trueImage = imageContato;
      } else {
        try {
          trueImage = await getImage(
            element.picture_filename_contact,
            element.picture_filetype_contact
          );
        } catch {
          trueImage = imageContato;
        }
      }

      return {
        id: element.id_contact,
        name: element.full_name_contact,
        image: trueImage,
        lastMessage: lastMessage,
        time: formattedDate,
        newMessages: element.is_viewed_message,
        number: element.id_contact.split("@")[0],
        lastMessageTimestamp: new Date(element.send_date_message),
        unreadMessageCount: parseInt(element.unread_message_count),
      };
    })
  );

  return array.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);
};

export const getMessages = (messages) => {
  messages.sort(
    (a, b) => new Date(a.send_date_message) - new Date(b.send_date_message)
  );
  return messages.map((element) => ({
    isMine: element.from_contact_message === null,
    message: formatMessage(element.content_message),
    fileName: element.file_name_message,
    fileType: element.file_type_message,
    sendDateMessage: element.send_date_message,
    isFlag: false,
    isNoteMessage: element.is_note_message,
    userName: extractUsername(element.content_message),
    isNote: element.is_note,
    senderUserId: element.sender_user_id_message,
  }));
};

export const extractImage = (image) => {
  // console.log(image);
  try {
    const partes = image.split(",", 2);
    if (partes.length > 1) {
      return partes[1];
    }
    return null;
  } catch (error) {
    // console.error(error);
    return null;
  }
};

export const formatLastMessageTimeStamp = (timestamp) => {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (new Date().getDate() === date.getDate()) {
    return `${hours}:${minutes}`;
  } else {
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}`;
  }
};

export function formatTimeSent(isoString) {
  const date = new Date(isoString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (isNaN(hours) && isNaN(minutes)) {
    return "agora mesmo";
  } else {
    return `${hours}:${minutes}`;
  }
}

export const formatDate = (date) => {
  const newDate = new Date(date);
  const day = String(newDate.getDate()).padStart(2, "0");
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  return `${day}/${month}`;
};

export const extractUsername = (model) => {
  const regex = /\(([^)]+) \u{1F464}\)/u;
  const match = regex.exec(model);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

export const formatMessage = (message) => {
  if (message === null) return null;
  if (message?.includes("👤)*")) {
    return message.split("👤)*")[1];
  }
  return message;
};
export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getFileType = (file) => {
  if (!file){
    return "chat";
  }
  const filetype = file.split(";")[0].split(":")[1].split("/")[0];
  if (filetype === "application" || filetype === "text") return "document";
  return filetype;
};

export function formatTimeSentWithDate() {
  const newDate = new Date();
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
}

