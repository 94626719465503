import UserApi from "./api/UserApi";

class UserService {
  async getUsers() {
    try {
      const response = await UserApi.getUsers();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getUserByIdContact(id) {
    try {
      const response = await UserApi.getUserByIdContact(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async createUser(data) {
    try {
      const response = await UserApi.createUser(data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getUserById(id) {
    try {
      const response = await UserApi.getUserById(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async updateUser(id, data) {
    try {
      const response = await UserApi.updateUser(id, data);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  
  async deleteUser(id) {
    try {
      const response = await UserApi.deleteUser(id);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async updateImage(id,file){
    try {
      const response = await UserApi.updateImage(id,file);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new UserService();
