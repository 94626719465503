import styled from 'styled-components';
import { AndroidLogo, Trash, PlusCircle, Pencil } from 'phosphor-react';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 1rem;
    @media (max-width: 800px) {
        flex-direction: column;
    }
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    gap: 8px;

    @media (max-width: 800px) {
        width: 100%;
    }
`;
    
export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    gap: 8px;
    
    @media (max-width: 800px) {
        width: 100%;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    gap: 8px;
`;

export const HeaderContact = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    margin-top: 2.5rem;
    @media (max-width: 800px) {
        margin-top: 1rem;
    }
`;

export const Title = styled.h1`
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const Subtitle = styled.h2`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const ListUsers = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    gap: 8px;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.Primary};
    border-radius: 8px;
    overflow: auto; 
    @media (max-width: 800px) {
        max-height: 35vh;
    }
`;

export const User = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 8px;
    padding: 1rem;
    border-radius: 8px;
    background-color: ${({ theme, $isSelected }) => $isSelected ? theme.colors.BackgroundMessageStrong : theme.colors.MainScreen};
    transition: background-color 0.1s ease-in;
    cursor: pointer;
    &:hover {
        background-color: ${({theme, $isSelected}) => $isSelected ? theme.colors.BackgroundMessageStrong : theme.colors.WhiteHover};
    }
`;

export const UserText = styled.h2`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const RootIcon = styled(AndroidLogo)`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.Text};
`;

export const ListContacts = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    gap: 8px;
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.colors.Primary};
    border-radius: 8px;
    overflow: auto; 
    @media (max-width: 800px) {
        max-height: 35vh;
    }
`;

export const DivRowSupContacts = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`

export const Contact = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 8px;
    padding: 1rem;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.MainScreen};
    transition: background-color 0.1s ease-in;
    cursor: pointer;
    &:hover {
        background-color: ${({ theme }) => theme.colors.WhiteHover};
    }
`;

export const ContactText = styled.h2`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Text};
`;

export const DivRowContact = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const StyledTrash = styled(Trash)`
    width: 24px;
    height: 24px;
    color: red;
`;

export const StyledPlusCircle = styled(PlusCircle)`
    width: 28px;
    height: 28px;
    color: ${({ theme }) => theme.colors.Text};
    cursor: pointer;
`;

export const ContactNotFound = styled.h2`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.Alert};
    text-align: center;
    padding: 0.5rem;
`;

export const UserActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const StyledPencil = styled(Pencil)`
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.colors.Text};
`;
