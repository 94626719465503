import React, { useEffect } from 'react';
import { Container, Header, Title, Subtitle, ContainerConfig, SelectTrigger, SelectContent, SelectItem } from './styles.js';
import {
  Root as SelectRoot,
  Value as SelectValue,
  ItemText as SelectItemText,
  Icon as SelectIcon
} from '@radix-ui/react-select';
import { useTheme } from '../../../../../../hook/theme.jsx';
import LocalStorage from '../../../../../../utils/LocalStorage.js';

const Geral = () => {
  const { handleThemeChange } = useTheme();
  const [theme, setTheme] = React.useState();
  const [emojiStyle, setEmojiStyle] = React.useState();


  useEffect(() => {
      setTheme(LocalStorage.getTheme());
      setEmojiStyle(LocalStorage.getEmojiStyle());
  }, []);
  
  const handleChange = (value) => {
    handleThemeChange(value);
  };

  const handleEmonjiStyleChange = (value) => {
    setEmojiStyle(value);
    LocalStorage.setEmojiStyle(value);
  }

  return (
    <Container>
      <Header>
        <Title>Geral</Title>
        <Subtitle>Tema:</Subtitle>
        <ContainerConfig>
          <Subtitle>Opções</Subtitle>
          <SelectRoot onValueChange={handleChange}>
            <SelectTrigger>
              <SelectValue placeholder={theme} />
              <SelectIcon />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="light">
                <SelectItemText>Claro</SelectItemText>
              </SelectItem>
              <SelectItem value="dark">
                <SelectItemText>Escuro</SelectItemText>
              </SelectItem>
            </SelectContent>
          </SelectRoot>
        </ContainerConfig>
        <Subtitle>Estilo de emoji:</Subtitle>
        <ContainerConfig>
          <Subtitle>Opções</Subtitle>
          <SelectRoot onValueChange={handleEmonjiStyleChange}>
            <SelectTrigger>
              <SelectValue placeholder={emojiStyle} />
              <SelectIcon />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="native">
                <SelectItemText>Nativo</SelectItemText>
              </SelectItem>
              <SelectItem value="twitter">
                <SelectItemText>Twitter</SelectItemText>
              </SelectItem>
              <SelectItem value="google">
                <SelectItemText>Google</SelectItemText>
              </SelectItem>
              <SelectItem value="apple">
                <SelectItemText>Apple</SelectItemText>
              </SelectItem>
              <SelectItem value="facebook">
                <SelectItemText>Facebook</SelectItemText>
              </SelectItem>
              </SelectContent>
          </SelectRoot>
        </ContainerConfig>
      </Header>
    </Container>
  );
};

export default Geral;
