import { api } from '../../api/axios'

export default new (class ContactApi {
  async getContact() {
    try {
      const response = await api.get('/contact')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getAllContacts() {
    try {
      const response = await api.get('/contact')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContactsByUser(id) {
    try {
      const response = await api.get(`/users-contacts/user/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async getUserContacts() {
    try {
      const response = await api.get('/users-contacts')
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async createUserContact(data) {
    try {
      const response = await api.post('/users-contacts', data)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async deleteUserContact(id) {
    try {
      const response = await api.delete(`/users-contacts/${id}`)
      return response
    } catch (error) {
      console.log(error)
      return error
    }
  }
})()