import React, { useState } from 'react'
import {
  Container,
  ContactImage,
  DivColumn,
  DivRow,
  NameContact,
  IconsEnd,
  ModalImage,
  EditButton,
  ModalImageSection,
  ModalNameSection,
  ModalNumberSection,
  InputBox,
  InputLabel,
  MoreVertIconStyled,
  SearchIconStyled,
  CaretContainer,
  CaretLeftStyled,
  UserListStyled,
} from './styles'

import Dialog from '../../../../../../shared/components/Dialog'
import imageContato from '../../../../../../assets/images/imageContato'
import { extractImage } from '../../../../../../utils/format'
import DialogUsers from './components/DialogUsers'

const ContactSuperior = ({ socket, dataContact, isModalOpen, setNavigation }) => {
  const [modalUsersOpen, setModalUsersOpen] = useState(false)
  const [isModalOpenLocal, setIsModalOpenLocal] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [editedNames, setEditedNames] = useState({
    [dataContact.id]: dataContact.name,
  })

  const handleOpenModal = () => {
    setIsModalOpenLocal(true)
  }

  const editContact = () => {
    setIsEditOpen(!isEditOpen)
  }

  const handleNameChange = (event) => {
    const newName = event.target.value
    setEditedNames((prevNames) => ({ ...prevNames, [dataContact.id]: newName }))
    const { id } = dataContact
    socket.emit('editContact', { id, newName })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      editContact()
    }
  }

  return (
    <Container>
      <DivRow>
        <CaretContainer onClick={() => setNavigation('contacts')}>
          <CaretLeftStyled weight="bold" />
        </CaretContainer>
        {extractImage(dataContact.image) !== 'null' ? (
          <ContactImage src={dataContact.image} alt="Imagem do contato" />
        ) : (
          <ContactImage src={imageContato} alt="Imagem do contato" />
        )}
        <DivColumn>
          <NameContact>
            {editedNames[dataContact.id] ?? dataContact.name}
          </NameContact>
        </DivColumn>
      </DivRow>
      <IconsEnd>
        <UserListStyled onClick={
          () => setModalUsersOpen(true)
        } />
        <SearchIconStyled />
        <MoreVertIconStyled
          onClick={handleOpenModal}
        />
      </IconsEnd>
      <DialogUsers id={dataContact.id} open={modalUsersOpen} onChangeOpen={setModalUsersOpen} />
      <Dialog
        open={isModalOpen || isModalOpenLocal}
        onChangeOpen={setIsModalOpenLocal}
      >
        <ModalImageSection>
          {extractImage(dataContact.image) !== 'null' ? (
          <ModalImage src={dataContact.image} alt="Imagem do contato" />
        ) : (
          <ModalImage src={imageContato} alt="Imagem do contato" />
        )}
        </ModalImageSection>
        <ModalNameSection>
          {isEditOpen ? (
            <>
              <InputLabel>Nome</InputLabel>
              <InputBox
                maxLength={20}
                value={editedNames[dataContact.id]}
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
              />
            </>
          ) : (
            <NameContact>{editedNames[dataContact.id]}</NameContact>
          )}
        </ModalNameSection>
        <ModalNumberSection>{dataContact.number}</ModalNumberSection>
        <EditButton onClick={editContact}>
          {isEditOpen ? 'Salvar' : 'Editar'}{' '}
          {/* Change button text based on edit mode */}
        </EditButton>
      </Dialog>
    </Container>
  )
}

export default ContactSuperior
